export function getErrorFormattedMessage(errorObject) {
  let errorMessage = 'An Error Occurred Please Try Again';
  if (!errorObject || Object.keys(errorObject).length === 0) return errorMessage;
  for (const errorKey of Object.keys(errorObject)) {
    const errorArr = errorObject[errorKey];
    if (errorArr.length === 0) continue;
    const errorStr = errorArr[0];
    const beautifiedProp = errorKey.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    errorMessage = `${beautifiedProp} - ${errorStr}`;
    break;
  }
  return errorMessage;
}

export class CustomException extends Error {
  constructor(message) {
    super(message);
    this.name = 'CustomException';
  }
}
