import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  orgDetailsStatus: APIStatus.idle,
  orgDetails: null,
  orgDetailsError: null,
  updateOrgDetailsStatus: APIStatus.idle,
  updateOrgDetailsReceipt: null,
  updateOrgDetailsError: null,
  enterpriseOrgsStatus: APIStatus.idle,
  enterpriseOrgs: null,
  enterpriseOrgsError: null,
  selectedEnterpriseOrg: null,
};

export const getOrgDetails = createAsyncThunk('getOrgDetails', async(payload) => {
  const response = await axios.get(`/organizations/${payload.organization}`);
  return response.data;
}, {
  condition: (arg, { getState, extra }) => {
    const state = getState();

    return state.organization.orgDetailsStatus !== APIStatus.loading;
  }
});

export const getEntepriseOrgs = createAsyncThunk('getEntepriseOrgs', async(payload) => {
  const response = await axios.get(`/${payload.enterpriseOrg}/enterprise-org`);
  return response.data;
});

export const updateOrgDetails = createAsyncThunk('updateOrgDetails', async(payload) => {
  const response = await axios.patch(`/organizations/${payload.organization}`, payload.data);
  return response;
});

const organizationsSlice = createSlice({
  name: 'organizationsSlice',
  initialState,
  reducers: {
    reset(state, action) {
      state = { ...initialState };
    },
    setselectedEnterpriseOrg(state, action) {
      state.selectedEnterpriseOrg = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getOrgDetails.pending, (state, action) => {
        state.orgDetailsStatus = APIStatus.loading;
      })
      .addCase(getOrgDetails.fulfilled, (state, action) => {
        state.orgDetailsStatus = APIStatus.loaded;
        state.orgDetails = action.payload;
      })
      .addCase(getOrgDetails.rejected, (state, action) => {
        state.orgDetailsStatus = APIStatus.failed;
        state.orgDetailsError = action.error;
      })
      .addCase(updateOrgDetails.pending, (state, action) => {
        state.updateOrgDetailsStatus = APIStatus.loading;
      })
      .addCase(updateOrgDetails.fulfilled, (state, action) => {
        state.updateOrgDetailsStatus = APIStatus.loaded;
        state.updateOrgDetailsReceipt = action.payload.data;
      })
      .addCase(updateOrgDetails.rejected, (state, action) => {
        state.updateOrgDetailsStatus = APIStatus.failed;
        state.updateOrgDetailsError = action.error;
      })
      .addCase(getEntepriseOrgs.pending, (state, action) => {
        state.enterpriseOrgsStatus = APIStatus.loading;
      })
      .addCase(getEntepriseOrgs.fulfilled, (state, action) => {
        state.enterpriseOrgsStatus = APIStatus.loaded;
        state.enterpriseOrgs = action.payload;
      })
      .addCase(getEntepriseOrgs.rejected, (state, action) => {
        state.enterpriseOrgsStatus = APIStatus.failed;
        state.enterpriseOrgsError = action.error;
      });

  }
});

export const { reset, setselectedEnterpriseOrg } = organizationsSlice.actions;

export default organizationsSlice.reducer;