import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { useLayoutState } from '../../context/LayoutContext';
import { PolygonManagementProvider } from '../../context/PolygonManagementContext';
import { RedirectPageProvider } from '../../context/RedirectPageContext';
import Header from '../Header';
import Sidebar from '../Sidebar';

import { usePageTracking } from './../../usePageTracking';
import useStyles from './styles';

const WorkDashboard = React.lazy(() => import('../../pages/work-dashboard/WorkDashboard'));
const Dashboard = React.lazy(() => import('../../pages/dashboard/Dashboard'));
const DashboardDetail = React.lazy(() => import('../../pages/dashboard/DashboardDetail'));
const DeviceManagement = React.lazy(() => import('../../pages/device-management'));
const AddDevice = React.lazy(() => import('../../pages/device-management/AddDevice'));
const EditDevice = React.lazy(() => import('../../pages/device-management/EditDevice'));
const DevicesNew = React.lazy(() => import('../../pages/devices-new/DevicesNew'));
const DeviceDetail = React.lazy(() => import('../../pages/device-detail/DeviceDetail'));
const DeviceDetails = React.lazy(() => import('../../pages/device-details/DeviceDetails'));
const OrgSessionsSummary = React.lazy(() => import('../../pages/sessionsSummary/OrgSessionsSummary'));
const WorkCyclesSummary = React.lazy(() => import('../../pages/workCyclesSummary/workCyclesSummary'));
const RedirectPage = React.lazy(() => import('../../pages/redirectPage/RedirectPage'));
const PolygonMap = React.lazy(() => import('../../pages/polygon-map/polygon-map'));
const LocationManagement = React.lazy(() => import('../../pages/locationManagement/LocationManagement'));
const ProcessedData = React.lazy(() => import('../../pages/processedData/ProcessedData'));
const ChangePassword = React.lazy(() => import('../../pages/changePassword/ChangePassword'));

var Layout = (props) => {
  var classes = useStyles();
  var layoutState = useLayoutState();
  usePageTracking();

  useEffect(() => {
    if (layoutState.dashboardList) {
      // console.log('layoutState.dashboardList', layoutState.dashboardList)
    }
  }, [layoutState.dashboardList]);

  return (
    <div className={classes.root}>
      <>
        {!window.ReactNativeWebView && <>
          <Header history={props.history} />
          {layoutState.isSidebarOpened && <Sidebar />}
        </>}
        <div
          className={classnames(classes.content, {
            // [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          {!window.ReactNativeWebView && <div className={classes.fakeToolbar} />}
          <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}> <CircularProgress size={24} /> </div>}>
            <Switch>
              <Route path="/app/workDashboard/:workCycleKey?">
                <WorkDashboard />
              </Route>
              <Route path="/app/dashboard/:workCycleKey/:locationKey">
                <DashboardDetail />
              </Route>
              <Route path="/app/dashboard/:workCycleKey">
                <Dashboard />
              </Route>
              <Route path="/app/deviceManagement/manageDevices">
                <DeviceManagement />
              </Route>
              <Route path="/app/deviceManagement/addDevice">
                <AddDevice />
              </Route>
              <Route path="/app/deviceManagement/:org/editDevice/:deviceId">
                <EditDevice />
              </Route>
              <Route path="/app/:org/deviceDetails/:deviceId">
                <DeviceDetail />
              </Route>
              <Route path="/app/:org/deviceDetailsInternal/:deviceId">
                <DevicesNew />
              </Route>
              <Route path="/app/devicesSummary">
                <DeviceDetails />
              </Route>
              <Route path="/app/sessionsSummary">
                <OrgSessionsSummary />
              </Route>
              <Route path="/app/workCyclesSummary">
                <WorkCyclesSummary />
              </Route>
              <Route path="/app/redirectPage">
                <RedirectPageProvider>
                  <RedirectPage />
                </RedirectPageProvider>
              </Route>
              <Route path="/app/polygonmap">
                <PolygonManagementProvider>
                  <PolygonMap />
                </PolygonManagementProvider>
              </Route>
              <Route path="/app/locationManagement">
                <LocationManagement />
              </Route>
              <Route path="/app/processedReports">
                <ProcessedData />
              </Route>
              <Route path="/app/changePassword">
                <ChangePassword />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </>
    </div>
  );
};

export default withRouter(Layout);
