import { configureStore } from '@reduxjs/toolkit';

import deviceGroupsSlice from './slices/deviceGroupsSlice';
import devicesSlice from './slices/devicesSlice';
import feedRulesSlice from './slices/feedRulesSlice';
import feedsSlice from './slices/feedsSlice';
import locationManagementSlice from './slices/locationManagementSlice';
import locationsSlice from './slices/locationsSlice';
import organizationsSlice from './slices/organizationsSlice';
import processedDataSlice from './slices/processedDataSlice';
import resetPasswordSlice from './slices/resetPasswordSlice';
import usersSlice from './slices/usersSlice';
import workCyclesSlice from './slices/workCyclesSlice';

export const Store = configureStore({
  reducer: {
    devices: devicesSlice,
    workCycles: workCyclesSlice,
    feeds: feedsSlice,
    locations: locationsSlice,
    processedData: processedDataSlice,
    deviceGroups: deviceGroupsSlice,
    organization: organizationsSlice,
    feedRules: feedRulesSlice,
    users: usersSlice,
    resetPasswordSlice,
    locationManagement: locationManagementSlice,
  }
});

// The thunk middleware was automatically added