import { AppBar, IconButton, Menu, Toolbar, Tooltip } from '@material-ui/core';
import { Person as AccountIcon, Menu as MenuIcon } from '@material-ui/icons';
import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getDevicesHealth,
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
  // getDashboardSettings
} from '../../context/LayoutContext';
import { signOut, useUserDispatch } from '../../context/UserContext';
import { revertAll } from '../../redux/globalSlice';
import { Typography } from '../Wrappers/Wrappers';

import useStyles from './styles';

export default function Header(props) {

  const dispatch = useDispatch();
  var classes = useStyles();
  const [username, ] = useState(localStorage.getItem('email'));
  const [dashboardName, ] = useState('urbanEye AI');
  const history = useHistory();

  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var [profileMenu, setProfileMenu] = useState(null);

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error && error.response && error.response.status === 401) {
      dispatch(revertAll());
      signOut(userDispatch, props.history);
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  });

  useEffect(() => {
    // setDashboardName(layoutState.dashboardSettings.name)
  }, [layoutState.devicesHealth, layoutState.devicesHealthError]);

  // useEffect(() => {
  //   if (layoutState.dashboardSettings && layoutState.dashboardSettings.name) {
  //     setDashboardName(layoutState.dashboardSettings.name)
  //   }
  // }, [layoutState.dashboardSettings, layoutState.dashboardSettingsError])

  useEffect(() => {
    getDevicesHealth(layoutDispatch);
    // getDashboardSettings(layoutDispatch)
    // eslint-disable-next-line
  }, []);

  const changePasswordHandler = () => {
    history.push({
      pathname: '/app/changePassword',
    });
    setProfileMenu(null);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <div style={{ textAlign: 'center', flexGrow: 1, paddingLeft: (layoutState.devicesHealth) ? 150 : 0 }}>
          <Typography variant="h5" weight="bold" style={{ color: '#C0FFB3'}} className={classes.logotype}>
            {dashboardName}
          </Typography>
          <div style={{ fontSize: '12px' }}>Powered by <a style={{ textDecoration: 'none', color: '#fff', fontWeight: 'bold' }} href="https://www.spaceage-labs.com/">SpaceAge Labs</a></div>
        </div>

        {layoutState && layoutState.devicesHealth && <Tooltip title="No. of Active Devices" placement="bottom">
          <div className={classes.deviceCount} style={{ backgroundColor: '#00CCCC' }}>{layoutState.devicesHealth.active}</div>
        </Tooltip>}
        {layoutState && layoutState.devicesHealth && <Tooltip title="No. of Alert Devices" placement="bottom">
          <div className={classes.deviceCount} style={{ backgroundColor: '#FFBA5A' }}>{layoutState.devicesHealth.alert}</div>
        </Tooltip>}
        {layoutState && layoutState.devicesHealth && <Tooltip title="No. of InActive Devices" placement="bottom">
          <div className={classes.deviceCount} style={{ backgroundColor: '#DFE1E6' }}>{layoutState.devicesHealth.inactive}</div>
        </Tooltip>}

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography style={{fontSize: 16}} weight="medium">
              {username}
            </Typography>
          </div>
          <div className={classes.profileMenuUser}>
            <div className={classes.profileMenuLinkWrapper}>
              <Typography
                style={{ marginRight: '10px', fontSize: 15, color: '#2c7873', fontWeight: 'bold'  }}
                className={classes.profileMenuLink}
                color="primary"
                weight="bold"
                onClick={()=>changePasswordHandler()}
              >
                Change Password
              </Typography>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                weight="bold"
                onClick={() => {
                  dispatch(revertAll());
                  signOut(userDispatch, props.history);
                }}
              >
                Sign Out
              </Typography>
            </div>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
